.tech-stack-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 600px;
  height: 100vh;
}

.icon-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.stack-heading {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 430px) {
  .tech-stack-container {
    height: 100vh;
  }
}
