.tech-logo-container {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 1px;
  border-radius: 10px;
  font-size: 3.4em;
  width: 17%;
  margin-bottom: 30px;
}

.tech-logo-text {
  font-size: 0.7rem;
  padding-top: 5px;
}
