.hero-container {
  height: calc(100vh - 70px);
  min-height: 600px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-heading-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.hero-text-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-image-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.hero-heading {
  font-size: 2.5em;
  font-weight: bold;
}

.hero-text {
  display: flex;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.8;
  letter-spacing: 1.8px;
  width: 50%;
}

.hero-image-frame {
  background-color: rgb(203, 203, 203);
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.hero-image {
  max-height: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 700px) {
  .hero-text {
    width: 70%;
  }
}

@media only screen and (max-width: 430px) {
  .hero-text {
    width: 90%;
  }
  .hero-heading-container {
    justify-content: center;
  }
  .hero-text-container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 350px) {
  .hero-heading {
    font-size: 2em;
  }
  .hero-text {
    font-size: 0.9em;
  }
  .hero-text {
    width: 100%;
  }
  .hero-image-frame {
    background-color: rgb(203, 203, 203);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
}
