/* --------------------------- */
/* --------Meyer Reset-------- */
/* --------------------------- */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* --------------------------- */
/* -----------Fonts----------- */
/* --------------------------- */

@font-face {
  font-family: 'CharlevoixPro';
  src: local('CharlevoixPro'),
    url(./fonts/CharlevoixPro/CharlevoixPro-Regular.otf) format('opentype');
}

/* --------------------------- */
/* ----------Layout----------- */
/* --------------------------- */

:root {
  --primary-color: #f0f2f5;
  --secondary-color: white;
  --font-color: #2e2e2e;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: CharlevoixPro, Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 0.1em;
  justify-content: center;
  background-color: var(--primary-color);
  overflow: auto;
  height: 100%;
  color: var(--font-color);
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
}
